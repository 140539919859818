<template>
  <v-select
    v-model="selectedFilters"
    label="Search Filters"
    :items="filters"
    item-text="name"
    item-value="key"
    background-color="input"
    :menu-props="{ offsetY: true, maxHeight: '600' }"
    multiple
    outlined
    hide-details
    small-chips
    dense>
    <template v-slot:selection="{ attrs, item }">
      <v-chip
        v-bind="attrs"
        :color="item.color || null"
        :dark="Boolean(item.color)"
        small>
        {{ getFilterText(item) }}
        <v-icon
          small
          right
          @click="removeFilter(item.key, true)">
          $delete
        </v-icon>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item>
        <v-list-item-content>
          <v-text-field
            :label="item.name"
            @click:clear="removeFilter(item.key)"
            @keyup.stop="handleInputValue(item)"
            background-color="input"
            class="pl-3"
            clearable
            dense
            hide-details
            outlined
            v-if="item.type === 'text'"
            v-model="params[`${item.key}`]">
          </v-text-field>
          <v-autocomplete
            @click="activeInput = item.key"
            :items="activeInput === 'page_name' ? pages : departments"
            :label="item.name"
            :menu-props="{ maxHeight: 225 }"
            @change="handleInputValue(item)"
            @click:clear="removeFilter(item.key)"
            auto-select-first
            background-color="input"
            class="pl-3"
            clearable
            dense
            hide-details
            :item-text="activeInput === 'page_name' ? 'page_name' : 'department'"
            :item-value="activeInput === 'page_name' ? 'page_name' : 'department'"
            outlined
            v-if="item.type === 'select'"
            v-model="params[`${item.key}`]">
          </v-autocomplete>
          <v-checkbox
            @change="handleInputValue(item)"
            class="pl-3 mt-0"
            label="Shippers/Pallets"
            v-if="item.type === 'boolean'"
            v-model="params[`${item.key}`]">
          </v-checkbox>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'SearchFilters',
  props: {
    departments: {
      type: Array
    },
    pages: {
      type: Array
    }
  },
  data () {
    return {
      activeInput: '',
      selectedFilters: [],
      filters: [
        { name: 'Page', key: 'page_name', type: 'select' },
        { name: 'Department', key: 'department', type: 'select' },
        { name: 'Item ID', key: 'item_id', type: 'text' },
        { name: 'Shippers/Pallets', key: 'is_shipper', type: 'boolean' }
      ],
      params: {
        page_name: '',
        item_id: '',
        department: '',
        is_shipper: null
      }
    }
  },
  computed: {
    activeFilters () {
      return Object.keys(this.params).filter(this.isActive)
    }
  },
  watch: {
    activeFilters: {
      handler: debounce(function (newValue) {
        if (newValue) {
          this.updateSearchFilters()
        }
      }, 400),
      deep: true
    }
  },
  created() {
    if (sessionStorage.search_filters) {
      const stored = JSON.parse(sessionStorage.search_filters)
      this.params = { ...this.params, ...stored }
      const activeKeys = Object.keys(stored).filter(key => Boolean(stored[key]))
      if (activeKeys.length > 0) this.selectedFilters.push(...activeKeys)
    }
  },
  methods: {
    isActive (key) {
      if (this.isSelected(key) && Boolean(this.params[key])) {
        const value = this.params[key]
        return Array.isArray(value) ? (value.length > 0) : true
      }
      return false
    },
    getFilterText (item) {
      if (item.key === 'is_shipper') {
        return item.name
      } else {
        return `${item.name}: "${this.params[item.key]}"`
      }
    },
    removeFilter (key, clearValue = false) {
      const index = this.selectedFilters.indexOf(key)
      if (index > -1) {
        this.selectedFilters.splice(index, 1)
        if (clearValue) this.params[key] = null
      }
    },
    handleInputValue: debounce(function (item) {
      if (item.type === 'boolean' && !this.params[item.key]) {
        this.removeFilter(item.key, true)
      }
      if (Boolean(this.params[item.key]) && !this.isSelected(item.key)) {
        this.selectedFilters.push(item.key)
      }
    }, 200),
    isSelected (key) {
      return this.selectedFilters.includes(key)
    },
    updateSearchFilters () {
      const filtersObj = this.activeFilters.reduce((obj, key) => {
        obj[key] = this.params[key]
        return obj
      }, {})
      this.$emit('input', filtersObj)
      if (this.activeFilters.length > 0) {
        sessionStorage.setItem('search_filters', JSON.stringify(filtersObj))
      } else {
        sessionStorage.removeItem('search_filters')
      }
    }
  }
}
</script>
